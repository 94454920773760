import { ActionProps } from '~/components/Actions/props';
import IconAlarmClock from '~/icons/AlarmClock';
import IconClipboardList from '~/icons/ClipboardList';
import IconStopWatch from '~/icons/StopWatch';
import IconTasks from '~/icons/Tasks';

const useTasks = ({
  espaceId,
  pathname,
}: {
  espaceId: string;
  pathname: string;
}): (ActionProps | ActionProps[])[] | undefined => {
  const isActive = (to: string, strict?: boolean): string | undefined => {
    if (strict) {
      return `/espaces/${espaceId}/tasks${to}` === pathname
        ? 'is-active'
        : undefined;
    }

    return pathname.slice(0, `/espaces/${espaceId}/tasks${to}`.length) ===
      `/espaces/${espaceId}/tasks${to}`
      ? 'is-active'
      : undefined;
  };

  return [
    {
      className: isActive(``, true),
      icon: IconTasks,
      label: 'Tâches',
      to: `/espaces/${espaceId}/tasks`,
    },
    {
      className: isActive(`/reminders`),
      icon: IconAlarmClock,
      label: 'Rappels',
      to: `/espaces/${espaceId}/tasks/reminders`,
    },
    {
      className: isActive(`/checklists`),
      icon: IconClipboardList,
      label: 'Listes de contrôle',
      to: `/espaces/${espaceId}/tasks/checklists`,
    },
    {
      className: isActive(`/tracking`),
      icon: IconStopWatch,
      label: 'Suivi',
      to: `/espaces/${espaceId}/tasks/tracking`,
    },
  ];
};

export default useTasks;
